import Button from '@components/Button';
import Loader from '@components/Loader';
import ProjectsContext from '@context/ProjectsContext';
import priceFormat from '@src/helpers/priceFormat';
import FormInput from '@utility/FormInput';
import FormNumber from '@utility/FormNumber';
import Link from '@utility/Link';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import cn from 'classnames';
import { deleteField } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import { REQUIRED_FIELD } from '../../../common';
import ButtonPlus from './ButtonPlus';

const ProjectCard = ({ projectId, productData, sku, isTradeUser }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [product, setProduct] = useState(null);
  const { register, handleSubmit } = useForm();
  const {
    register: registerQty,
    handleSubmit: handleQty,

    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const { updateProject } = useContext(ProjectsContext);
  const slug =
    process.env.GATSBY_DEVELOPMENT_MODE === 'true'
      ? `https://rbwqasite.gatsbyjs.io${productData.slug}`
      : productData.slug;

  useIsomorphicLayoutEffect(() => {
    fetch(slug)
      .then((response) => response.text())
      .then((str) => {
        // Initialize the DOM parser
        const parser = new DOMParser();

        // Parse the text
        const doc = parser.parseFromString(str, 'text/html');
        const data = JSON.parse(
          doc.getElementById('js-project-card').innerHTML,
        );
        setProduct(data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [sku, slug]);

  const onSubmit = (data) => {
    updateProject(projectId, {
      [`products.${sku}.fixture`]: data?.fixture ? data.fixture : '',
      [`products.${sku}.instructions`]: data?.instructions
        ? data.instructions
        : '',
    });
  };

  const updateQuantity = (data) => {
    updateProject(projectId, {
      [`products.${sku}.quantity`]: data?.quantity ? Number(data.quantity) : 1,
    });
  };

  const removeProduct = () => {
    updateProject(projectId, {
      [`products.${sku}`]: deleteField(),
    });
  };

  const renderCardOverlay = () => (
    <div
      className={cn('project-card__overlay', {
        'project-card__overlay--visible': warning,
      })}
    >
      <div className="project-card__overlay-container">
        <p className="project-card__overlay-text">
          Are you sure you want to remove this item from your project?
        </p>
        <div className="project-card__overlay-buttons">
          <Button
            onClick={() => removeProduct()}
            className="project-card__overlay-button link"
            aria-label="Confirm remove project"
          >
            Yes, remove!
          </Button>
          <Button
            onClick={() => setWarning(false)}
            className="project-card__overlay-button link"
            aria-label="Cancel remove project"
          >
            No, keep it!
          </Button>
        </div>
      </div>
    </div>
  );
  const renderProjectCard = () => {
    if (error) {
      return (
        <div className="project-card">
          <div className="project-card__inner">
            <div className="project-card__remove">
              <Button
                className="project-card__remove-button"
                onClick={() => setWarning(true)}
                aria-label="Remove product"
              />
            </div>
            <p className="type-sans-330 text-mono-500">
              <span className="mb-6 type-upper-070 block">{sku}</span>
              Whoops, looks like this product no longer exists. <br />{' '}
              Please&nbsp;
              <Link to="/contact-us" className="inline-link" styled={false}>
                contact us
              </Link>
              &nbsp;for assistance.
            </p>
            {renderCardOverlay()}
          </div>
        </div>
      );
    }
    if (product) {
      return (
        <div>
          <div className="project-card">
            <div className="project-card__inner">
              <div className="project-card__img-container">
                <Link to={productData.slug} styled={false}>
                  <img
                    className="project-card__img"
                    src={product.image}
                    alt=""
                  />
                </Link>
              </div>
              <div className="project-card__content">
                <div className="project-card__remove print:hidden">
                  <Button
                    className="project-card__remove-button"
                    onClick={() => setWarning(true)}
                    aria-label="Project card Remove"
                  />
                </div>
                <div className="project-card__title">
                  <Link to={productData.slug} styled={false}>
                    {product.name}
                  </Link>
                </div>
                <Link to={productData.slug} styled={false}>
                  <p className="project-card__sku">{sku.replace("0_1", "0.1")}</p>
                </Link>
                {product.description && (
                  <ul className="project-card__section flex-col">
                    {Object.entries(product.description).map((val) => (
                      <li className="project-card__detail" key={val[1].attr}>
                        <span className="project-card__detail-label">
                          {val[1].attr}
                        </span>
                        <span className="project-card__detail-text">
                          {val[1].value}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="project-card__section">
                  <span className="project-card__section-label">Quantity</span>
                  <span className="project-card__section-text">
                    <form>
                      <FormNumber
                        {...registerQty('quantity', { min: 1, required: true })}
                        defaultValue={productData.quantity}
                        onBlur={handleQty(updateQuantity)}
                        errorMessage={
                          errors.quantity &&
                          (errors.quantity.type === 'min'
                            ? 'Min 1 product required'
                            : REQUIRED_FIELD)
                        }
                      />
                    </form>
                  </span>
                </div>
                <div className="project-card__section project-card__section--price">
                  <span className="project-card__section-label">
                    {isTradeUser ? 'Trade Price' : 'Price'}
                  </span>
                  {
                    !product.price &&
                    <span className="project-card__section-price">
                      Request a quote for pricing
                    </span>
                  }
                  {product.price && <span className="project-card__section-price">
                    {isTradeUser &&
                    +product.discount_price !== +product.price ? (
                      <div className="text-right">
                        <span
                          className={cn({
                            'text-green': isTradeUser,
                          })}
                        >
                          {priceFormat(+product.discount_price)}
                        </span>
                        <span className="project-card__discount-price">
                          {priceFormat(+product.price)}
                        </span>
                      </div>
                    ) : (
                      priceFormat(+product.price)
                    )}
                  </span>}
                </div>
                <div className="project-card__fields print:hidden">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton className="mt-8">
                          <ButtonPlus>
                            Add Fixture & Special Instructions
                          </ButtonPlus>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="project-card__fields-inner">
                        <div className="mt-4">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <FormInput
                              {...register('fixture')}
                              placeholder="Enter Fixture Type"
                              defaultValue={productData?.fixture}
                            />
                            <FormInput
                              {...register('instructions')}
                              tag="textarea"
                              placeholder="Enter Special Instructions"
                              rows="5"
                              className="project-card__fields-textarea"
                              defaultValue={productData?.instructions}
                            />
                            <AccordionItemButton>
                              <Button
                                type="submit"
                                className="project-card__fields-submit"
                                aria-label="Submit"
                              >
                                Submit
                              </Button>
                            </AccordionItemButton>
                          </form>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
            {renderCardOverlay()}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {loading ? (
        <div className="border border-solid border-mono-300 flex tems-center justify-center">
          <div className="-mt-4 py-32">
            <Loader />
          </div>
        </div>
      ) : (
        renderProjectCard()
      )}
    </div>
  );
};

ProjectCard.propTypes = {
  projectId: PropTypes.string.isRequired,
  productData: PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    slug: PropTypes.string,
  }).isRequired,
  sku: PropTypes.string.isRequired,
  isTradeUser: PropTypes.bool.isRequired,
};

export default ProjectCard;
