import Button from '@components/Button';
import ColumnLayout from '@components/ColumnLayout';
import SEO from '@components/seo';
import imageUrlFor from '@helpers/imageUrlFor';
import FormError from '@utility/FormError';
import FormHoneypot from '@utility/FormHoneypot';
import FormInput from '@utility/FormInput';
import FormSelect from '@utility/FormSelect';
import Link from '@utility/Link';
import cn from 'classnames';
import { serverTimestamp, setDoc } from 'firebase/firestore';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CREATE_ACCOUNT_USER_ROLES,
  EMAIL_ERROR_MESSAGE,
  EMAIL_FIELD_PATTERN,
  REQUIRED_FIELD,
} from '../../../common';
import Spinner from '../../../static/images/loader-circle.gif';
import withFirebase from '../Firebase/withFirebase';

const Create = ({ firebase }) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawCreateAccount(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `,
  );
  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onBlur',
  });

  const { errors } = formState;

  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { _rawCreateAccount } = accountData.allSanityAccount.edges[0].node;
  const errorHandler = (error) => {
    setSubmitError(error);
    setIsSubmitting(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    let uuid = ''
    firebase
      .firebaseCreateUserWithEmailAndPassword(data.email, data.passwordOne)
      .then((newAuthUser) =>{
        uuid = newAuthUser.user.uid
        // Create associated user in users firestore collection
        // Refresh the token if the user is already logged in
        // under another session
        return firebase.auth.currentUser
          .getIdToken(true)
          .then(() => firebase.auth.currentUser.reload())
          .then(() =>
            setDoc(
              firebase.user(newAuthUser.user.uid),
              {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                company: data.company,
                user_type: decodeURI(data.userRole),
                user_trade: false,
                created: serverTimestamp(),
              },
              { merge: true },
            )
            .catch((error) => {
              let userData = {
                email: data.email,
                name: data.firstName + ' ' + data.lastName,
                id: newAuthUser.user.uid
              }
              firebase.sendMailOnUserCreationFailure(userData).then((res)=>{
                console.log("Email sent. Error:", error)
              });
            }),
          )}
      )
      .then(() => firebase.sendVerficationCodeToMail({email: data.email}))
      .then(() => {
        localStorage.setItem('hasCreatedRBWAccount', true);
        firebase.firebaseSignOut();
        return navigate('/account/verify-inbox', {state: {email: data.email, uuid, password: data.passwordOne }});
      })
      .catch((error) => errorHandler(error));
  };

  return (
    <ColumnLayout
      heading={_rawCreateAccount.heading ? _rawCreateAccount.heading : ''}
      subheading={
        _rawCreateAccount.subheading ? _rawCreateAccount.subheading : ''
      }
      image={_rawCreateAccount.image && _rawCreateAccount.image}
    >
      <SEO
        title={_rawCreateAccount?.heading || null}
        description={_rawCreateAccount?.subheading || null}
        image={
          _rawCreateAccount?.image?.asset
            ? imageUrlFor(_rawCreateAccount.image).url()
            : null
        }
      />
      {submitError && submitError.message && (
        <FormError>
          <p>{submitError.message}</p>
        </FormError>
      )}
      {/* "handleSubmit" will validate your FormInputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="First Name"
          {...register('firstName', { required: true })}
          placeholder="Enter your first name"
          errorMessage={errors.firstName && REQUIRED_FIELD}
        />
        <FormInput
          label="Last Name"
          {...register('lastName', { required: true })}
          placeholder="Enter your last name"
          errorMessage={errors.lastName && REQUIRED_FIELD}
        />
        <FormInput
          label="Company"
          {...register('company', { required: true })}
          placeholder="Company"
          errorMessage={errors.company && REQUIRED_FIELD}
        />
        <FormSelect
          {...register('userRole', { required: true })}
          label="Select your role"
          placeholder="Select role"
          defaultValue=""
          errorMessage={errors.userRole && REQUIRED_FIELD}
          className={cn({ 'text-mono-600': watch('userRole') })}
        >
          <>
            {CREATE_ACCOUNT_USER_ROLES.map((role) => (
              <option key={encodeURI(role)} value={encodeURI(role)}>
                {role}
              </option>
            ))}
          </>
        </FormSelect>
        <FormInput
          label="Email"
          type="text"
          placeholder="Enter your email address"
          {...register('email', {
            required: true,
            pattern: EMAIL_FIELD_PATTERN,
          })}
          errorMessage={
            errors.email &&
            (errors.email.type === 'required'
              ? REQUIRED_FIELD
              : EMAIL_ERROR_MESSAGE)
          }
        />
        <FormInput
          label="Password"
          {...register('passwordOne', { required: true, minLength: 6 })}
          type="password"
          placeholder="Create a password"
          errorMessage={
            errors.passwordOne &&
            (errors.passwordOne.type === 'required'
              ? REQUIRED_FIELD
              : 'This password is too weak, passwords should be atleast 6 characters.')
          }
        />
        <FormInput
          {...register('passwordTwo', {
            required: true,
            validate: (value) => value === watch('passwordOne'),
          })}
          label="Confirm Password"
          placeholder="Re-enter password"
          type="password"
          errorMessage={
            errors.passwordTwo &&
            (errors.passwordTwo.type === 'required'
              ? REQUIRED_FIELD
              : 'The passwords must match')
          }
        />
        <FormHoneypot register={register} />
        <Button
          aria-label="Submit"
          type="submit"
          className={cn(
            'btn-default btn-black btn-lg mt-4 btn-interaction',
            {
              'btn-black-disabled': !formState.isValid,
            },
            { 'is-loading': isSubmitting },
          )}
          disabled={isSubmitting}
        >
          <>
            <span>Create Profile</span>
            <span>
              <img
                src={Spinner}
                alt="Loading"
                width="16px"
                height="16px"
                className="ml-6 absolute left-0 top-0"
              />
              Loading
            </span>
          </>
        </Button>
      </form>
      <p className="type-sans-030 mt-6">
        Already have a Profile?&nbsp;
        <Link to="/account/login" styled={false} className="inline-link" aria-label="Login">
          Login
        </Link>
      </p>
    </ColumnLayout>
  );
};

Create.propTypes = {
  firebase: PropTypes.shape({
    firebaseCreateUserWithEmailAndPassword: PropTypes.func,
    firebaseSendEmailVerification: PropTypes.func,
    user: PropTypes.func,
    firebaseSignOut: PropTypes.func,
    auth: PropTypes.object,
  }).isRequired,
};

export default withFirebase(Create);
