import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import ButtonPlus from './ButtonPlus';
import cn from 'classnames';
import * as styles from '../Profile/styles.module.scss';

const ProjectRead = ({ setEditMode, project }) => {
  return (
    <div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <h3 className={styles.listLabel}>Application Type</h3>
          <p className={styles.listAttribute}>{project?.application_type}</p>
        </div>
        <div className={styles.listLastItem}>
          <h3 className={styles.listLabel}>Project Country</h3>
          <p className={styles.listAttribute}>{project?.address?.country}</p>
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <h3 className={styles.listLabel}>Project State</h3>
          <p className={styles.listAttribute}>{project?.address?.state}</p>
        </div>
        <div className={styles.listLastItem}>
          <h3 className={styles.listLabel}>City</h3>
          <p className={styles.listAttribute}>{project?.address?.city}</p>
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <h3 className={styles.listLabel}>Zipcode</h3>
          <p className={styles.listAttribute}>{project?.address?.zip}</p>
        </div>
        <div
          className={cn(styles.listLastItem, {
            'print:hidden': !project?.address?.address,
          })}
        >
          <h3 className={styles.listLabel}>Project Address</h3>
          <p className={styles.listAttribute}>{project?.address?.address}</p>
          {!project?.address?.address && (
            <div className="print:hidden">
              <ButtonPlus onClick={() => setEditMode(true)} aria-label="Add Project Address">
                Add Project Address
              </ButtonPlus>
            </div>
          )}
        </div>
      </div>
      <div className={styles.listRow}>
        <div
          className={cn(styles.listFirstItem, {
            'print:hidden': !project?.specifier,
          })}
        >
          <h3 className={styles.listLabel}>Specifier</h3>
          <p className={styles.listAttribute}>{project?.specifier}</p>
          {!project?.specifier && (
            <div className="print:hidden">
              <ButtonPlus onClick={() => setEditMode(true)} aria-label="Add specifier name">
                Add Specifier Name
              </ButtonPlus>
            </div>
          )}
        </div>
      </div>
      <Button
        className="type-sans-030 link focus:outline-none w-auto mt-8 mr-12 print:hidden"
        onClick={() => setEditMode(true)}
        aria-label="Edit Details"
      >
        Edit Details
      </Button>
    </div>
  );
};
ProjectRead.propTypes = {
  setEditMode: PropTypes.func.isRequired,
  project: PropTypes.shape({
    application_type: PropTypes.string,
    address: PropTypes.object,
  }).isRequired,
};

export default ProjectRead;
